<script>
import PopperModal from "@/components/elements/PopperModal.vue";

export default {
  name: "RsMain",
  data() {
    return {
      // cycle.created (@hotfix for child component issues when using router-view)
      cycle: {
        created: false,
      },
      toEdit: false,
      swalOptions: {
        'title': 'Sweet Alert Title',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      },
      tmp: {
        managementCompanyName: null,
      },
      mainReport: {},
      reportStatus: {},
      filteredPort: [],
      editStatus: false,
      currentStatus: null,
    }
  },
  mixins: [ObjectMixin, DateMixin, AttachmentMixin, permissionMixin],
  components: {
    PopperModal
  },
  methods: {
    ...mapActions([
      'addRequestKeys', 'setRequestKeys', 'generateRequestKeys', 'updateRsInfo', 'getCrewKeys',
      'getRsReport',
    ]),
    async loadResources() {
      swal.fire({...this.swalOptions, ...{title: 'LOADING RESOURCES'}});
      swal.showLoading();
      await this.addRequestKeys([
        'getReportStatusKeys',
        'getMngmtOfficeKeys',
        'getCountryListKeys',
        'getMngmtCompanyKeys',
        'getPortKeys',
        'getUserKeys',
      ]);
      await this.generateRequestKeys({});
      swal.close();
    },
    async loadCms() {
      const inspection_commencement_date = this.rsGet('inspection_commencement_date');
      let vesselName = this.rsGet('vessel.name');
      vesselName = (typeof vesselName !== 'undefined') ? String(vesselName).toUpperCase() : null;
      let crewListParam = {
        onboard_from: inspection_commencement_date,
        onboard_to: inspection_commencement_date,
        vesselName: vesselName,
      }

      swal.fire({...this.swalOptions, ...{title: 'ESTABLISHING CONNECTION TO CMS'}});
      swal.showLoading();
      const response = await this.getCrewKeys(crewListParam);
      swal.close();

      if (response.hasOwnProperty('errors')) {
        let errors = response.errors
        let error_message = '';
        error_message += '<ol style="text-align: left; font-size: 1em">';
        Object.keys(errors).forEach(key => {
          let err_msg = errors[key].join(',');
          error_message += '<li class="e-text-red"><small>* ' + err_msg + '</small></li>';
        });
        error_message += '</ol>';
        await AlertService.errorAlert(error_message, 'GET CREW ONBOARD LISTS');
      }
    },
    editMainReport() {
      this.toEdit = true;
      this.mainReport = Object.assign({}, {
        id: this.rsGet('id'),
        inspection_commencement_date: this.rsGet('inspection_commencement_date'),
        title: this.rsGet('title'),
        port_id: this.rsGet('port_id'),
        management_office_id: this.rsGet('management_office_id'),
        country_id: this.rsGet('country_id'),
        author: this.rsGet('author'),
        person_in_charge_id: this.rsGet('person_in_charge_id'),
        responsible_person_id: this.rsGet('responsible_person_id'),
      });
    },
    changeTmpManagementCompanyName() {
      this.tmp.managementCompanyName = null;
      let managementOffice = {};
      let managementCompany = {};

      if (this.mainReport.management_office_id) {
        managementOffice = Object.values(this.mngmntOfficeKeys.filter(item => item.id === this.mainReport.management_office_id))[0];

        if (managementOffice.hasOwnProperty('management_company_id')) {
          managementCompany = Object.values(this.mngmtCompanyKeys.filter(item => item.id === managementOffice.management_company_id))[0];
        }
      }

      this.tmp.managementCompanyName = managementCompany.hasOwnProperty('name') ? managementCompany.name : null;
    },

    async updateRs() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        const response = await this.updateRsInfo(this.mainReport);
        if (response) {
          AlertService.successAlert('Rightship updated successfully', 'UPDATE RIGHTSHIP');
          this.resetFields();
        }
      }
    },

    resetFields() {
      this.toEdit = false;
      this.mainReport = {};
      this.filteredPort = [];
      this.editStatus = false;
      this.tmp.managementCompanyName = null;
    },

    async cancelUpdate() {
      if (await AlertService.cancelAlert()) {
        this.resetFields();
      }
    },

    editReportStatus() {
      const reportStatusId = this.rsGet('report_status_log.report_status_id');
      this.currentStatus = reportStatusId
      this.reportStatus = {
        id: this.rsGet('id'),
        report_status_log: {
          report_status_id: reportStatusId,
          rs_id: this.rsGet('id'),
        }
      }
      this.editStatus = true
    },
    async saveStatus() {
      let currStatusName = this.reportStatusKeys.filter(stat => stat.id === this.currentStatus)[0]?.name;
      const changeReportStatusName = this.reportStatusKeys.filter(stat => stat.id === this.reportStatus.report_status_log.report_status_id)[0].name;

      currStatusName = !currStatusName ? '' : currStatusName;

      const verify = swal.fire({
        icon: 'question',
        title: 'CHANGE REPORT STATUS',
        html: `CHANGING <strong> ${currStatusName} </strong> STATUS TO <strong> ${changeReportStatusName} </strong> STATUS `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'PROCEED',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545'
      }).then(result => {
        return result;
      });

      if ((await verify).isConfirmed) {
        const response = await this.updateRsInfo(this.reportStatus);
        if (response) {
          AlertService.successAlert('Rightship Report Status updated successfully', 'UPDATE RIGHTSHIP REPORT STATUS');
          this.resetFields();
        }
      }
    },
  },
  async created() {
    let params = {id: this.$route.params.id};
    let response = await this.getRsReport(params);

    if (response.status !== 200) {
      if (response.status === 404) {
        return await this.$router.push({name: 'RsIndex'});
      }

      return false;
    }

    await this.loadResources();
    await this.loadCms();
    await this.getRsAttachment(1, this.rsReport, 'inspection.id');

    this.cycle.created = true;
  },
  computed: {
    ...mapGetters([
      'rsReport',
      'reportStatusKeys',
      'mngmntOfficeKeys',
      'mngmtCompanyKeys',
      'countryKeys',
      'portKeys',
      'requestKeys',
      'userKeys',
    ]),
  },
  watch: {
    'mainReport.country_id'(id) {
      this.filteredPort = this.portKeys.filter(port => port.country_id === id);
    }
  }
}

import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "@/mixins/ObjectMixin";
import DateMixin from "@/mixins/DateMixin";
import AttachmentMixin from "@/mixins/attachmentMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import {AlertService} from "@/services/AlertService";
</script>

<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="updateRs">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              RIGHTSHIP INSPECTION
            </h5>
          </div>
          <div class="col-auto">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelUpdate" v-if="toEdit">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" type="submit">Save</button>
            </template>
            <template v-else>
              <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="editMainReport"
                      v-if="isElite() || (isAllowed('rs.main.edit') && isOwnVessel('rsReport'))">
                Edit Report
              </button>
              <div class="float-right ml-2 dropdown dropright" v-if="1==0">
                <button id="actionAIRDropdown" aria-expanded="false" aria-haspopup="true" class="mr-2 e-btn e-btn-blue-gray force-white-all"
                        data-toggle="dropdown">
                  <font-awesome-icon class="mr-1" icon="download"/>
                  Download RightShip Inspection
                </button>
                <div aria-labelledby="actionAIRDropdown" class="dropdown-menu">
                  <button class="dropdown-item text-secondary font-weight-bolder" type="button">
                    <font-awesome-icon icon="file-excel"/>
                    Excel
                  </button>
                  <button class="dropdown-item text-secondary font-weight-bolder" type="button">
                    <font-awesome-icon icon="file-pdf"/>
                    PDF
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>

        <table class="w-100">
          <tr>
            <td style="width: 12%;">Report Number</td>
            <td style="width: 50%;">{{ rsGet('code') | toUpper }}</td>
            <td style="width: 20%;">
              Status
              <font-awesome-icon icon="question-circle" title="test hover" data-toggle="modal" data-target="#popperModal"></font-awesome-icon>
            </td>
            <td>
              <template v-if="editStatus">
                <div class="row align-content-center align-items-center">
                  <div class="col-auto">
                    <select class="form-control form-control-sm" v-model="reportStatus.report_status_log.report_status_id">
                      <template v-for="reportStatusKey in reportStatusKeys">
                        <option v-if="reportStatusKey.visible" :value="reportStatusKey.id" :disabled="reportStatusKey.disabled">
                          {{ reportStatusKey.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-success btn-xs mr-2"
                            :disabled="currentStatus===reportStatus.report_status_log.report_status_id" @click="saveStatus">
                      <font-awesome-icon icon="save" class="force-white-all">

                      </font-awesome-icon>
                      Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" @click="editStatus=false;">
                      <font-awesome-icon icon="ban" class="force-white-all"></font-awesome-icon>
                      Cancel
                    </button>
                  </div>
                </div>
              </template>
              <button v-else type="button" class="e-btn e-btn-xs px-5" @click="editReportStatus"
                      :class="['bg-'+rsGet('report_status_color', '42b983')]">
                {{ rsGet('report_status_name', 'NEW') | toUpper }}
              </button>
            </td>
          </tr>
          <tr>
            <td>Vessel <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase">
              {{ rsGet('vessel_name') }} <span class="font-weight-bolder">({{ rsGet('vessel_flag_name') | toUpper }})</span>
              <!--              AEGEAN HIGHWAY <span class="font-weight-bolder">(PANAMA)</span>-->
            </td>
            <td>Inspection Commencement Date / Time <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase">
              <input type="datetime-local" v-if="toEdit" v-model="mainReport.inspection_commencement_date" min="1900-01-01" :max="currentDate + 'T23:59'" required
                     class="form-control-sm form-control" name="inspection_commencement_date" id="inspection_commencement_date">
              <span v-else>
                {{ rsGet('inspection_commencement_date') | moment('DD MMMM YYYY - hh:mm A') }}
              </span>
            </td>
          </tr>
          <tr>
            <td>Report Title <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-capitalize">
              <input type="text" v-if="toEdit" v-model="mainReport.title" class="form-control-sm form-control" name="report-title"
                     id="report-title">
              <span v-else>{{ rsGet('title') | toUpper }}</span>
            </td>
            <td>Inspection Completion Date / Time <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase">
              <input type="datetime-local" v-if="toEdit" v-model="mainReport.inspection_completion_date" :min="mainReport.inspection_commencement_date ? mainReport.inspection_commencement_date : currentDate + 'T00:00'" :max="currentDate + 'T23:59'"
                     class="form-control-sm form-control"
                     name="inspection_completion_date" id="inspection_completion_date">
              <span v-else>
<!--                {{ rsGet('inspection_completion_date') | moment('DD MMMM YYYY - hh:MM A') }}-->
                {{ rsGet('inspection_completion_date') | moment('DD MMMM YYYY - hh:mm A') }}
              </span>
            </td>
          </tr>
        </table>
        <hr/>
        <table class="w-100">
          <tr>
            <td style="width: 15%" class="align-middle">Country of Inspection</td>
            <td class="text-uppercase align-middle">
              <select name="country-of-inspection" v-model="mainReport.country_id" class="form-control form-control-sm"
                      id="country-of-inspection" v-if="toEdit">
                <option :value="null">--- SELECT COUNTRY ---</option>
                <option :value="country.id" v-for="country in countryKeys">
                  {{ country.name | toUpper }}
                </option>
              </select>
              <span v-else style="white-space: pre-line">{{ rsGet('country.name') | toUpper }}</span>
            </td>
            <td class="align-middle" style="width: 15%">Port of Inspection</td>
            <td class="text-uppercase align-middle">
              <select name="port-of-inspection" v-model="mainReport.port_id" class="form-control form-control-sm" id="port-of-inspection"
                      v-if="toEdit" :disabled="!filteredPort.length">
                <option :value="null">--- SELECT PORT ---</option>
                <option :value="port.id" v-for="port in filteredPort">{{ (port.code ? port.code.toUpperCase() : '') }}
                  {{ port.name ? port.name.toUpperCase() : '' }}
                </option>
              </select>
              <span v-else style="white-space: pre-line">{{ rsGet('port.name') | toUpper }}</span>
            </td>
            <td style="width: 15%" class="align-middle"></td>
            <td class="text-uppercase align-middle"></td>
          </tr>
          <tr>
            <td class="align-middle" style="width: 15%">Management Branch Company</td>
            <td class="text-uppercase align-middle">
              <span style="white-space: pre-line">
                {{ rsGet('management_office.management_company.name', tmp.managementCompanyName, true) }}
              </span>
            </td>
            <td style="width: 15%" class="align-middle">Management Branch Office</td>
            <td class="text-uppercase align-middle">
              <select name="management-branch" v-model="mainReport.management_office_id" class="form-control form-control-sm"
                      id="management-branch" v-if="toEdit" @change="changeTmpManagementCompanyName">
                <option :value="null">--- SELECT MANAGEMENT OFFICE ---</option>
                <option :value="office.id" v-for="office in mngmntOfficeKeys ">
                  {{ office.name | toUpper }}
                </option>
              </select>
              <span style="white-space: pre-line" v-else>{{ rsGet('management_office.name') | toUpper }}</span>
            </td>
            <td style="width: 15%" class="align-middle"></td>
            <td class="text-uppercase align-middle"></td>
          </tr>
          <tr>
            <td class="pb-0" style="width: 15%;">Person In Charge <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td style="width: 21%;" class="text-capitalize font-weight-bold pb-0">
              <select name="management-branch" v-model="mainReport.person_in_charge_id" class="form-control form-control-sm"
                      id="management-branch" v-if="toEdit">
                <option :value="null">--- SELECT PERSON IN CHARGE ---</option>
                <option :value="person.id" v-for="person in userKeys ">
                  {{ person.name | toUpper }}
                </option>
              </select>
              <span style="white-space: pre-line" v-else>{{ rsGet('person_in_charge.name') }}</span>
            </td>
            <td class="pb-0" style="width: 15%;">Responsible Person <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td style="width: 21%;" class="text-capitalize font-weight-bold pb-0">
              <select name="management-branch" v-model="mainReport.responsible_person_id" class="form-control form-control-sm"
                      id="management-branch" v-if="toEdit">
                <option :value="null">--- SELECT RESPONSIBLE PERSON ---</option>
                <option :value="responsible.id" v-for="responsible in userKeys ">
                  {{ responsible.name | toUpper }}
                </option>
              </select>
              <span style="white-space: pre-line" v-else>{{ rsGet('responsible_person.name') }}</span>
            </td>
            <td class="pb-0" style="width: 15%;">Report Author</td>
            <td class="text-capitalize font-weight-bold pb-0">
              <input type="text" v-if="toEdit" v-model="mainReport.author" class="form-control-sm form-control" name="author"
                     id="date-of-inspection">
              <span style="white-space: pre-line" v-else>{{ rsGet('author') | toUpper }}</span>
            </td>
          </tr>
        </table>
      </form>
    </div>
    <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'RsGeneral'}">
              <span>General</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'RsInspection'}">
              <span>Inspection</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'RsNonConformity'}">
              <span>Findings</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'RsShipParticular', params: {vesselId: rsReport.vessel_id } }">
              <span>Ship's Particular</span>
            </router-link>
          </li>

        </ul>
      </div>
    </nav>
    <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
    <router-view v-if="cycle.created"/>
  </div>
</template>

<style scoped lang="scss">
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}

table td {
  padding: 2px !important;
}
</style>
